@import "reset.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

div,
p,
span {
	font-family: Lato, "Lato", Inter, "Inter", sans-serif !important;
	font-size: 13px;
}

html,
body,
#root {
	height: 100%;
	background: #f6f6f6 !important;
}

button,
input {
	border: none;
	outline: none;
	box-shadow: none;
	border-radius: 0;
	color: inherit;
	line-height: normal;
	overflow: visible;
	background-color: transparent;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

input[type="number"] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
