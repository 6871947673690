.nav {
  height: calc(100vh - 97px);
  flex: 0 1 250px;
  min-width: 230px;
  margin-left: 10px;
  padding-top: 40px;
}
.listInfo {
  padding-left: 0px;
}

.liItem {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
  margin-left: 12px;
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  padding-bottom: 5px;
}

.name {
  color: #647079;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
}

.active {
  background-color: #ffffff;
  border-radius: 4px;
}
.active .name {
  color: #03a9f4;
}
.icons {
  width: 20px;
  height: 20px;
  fill: #647079;
}
.iconActive {
  fill: #03a9f4;
}
.activeDefault {
  background-color: #ffffff;
  border-radius: 4px;
}
