.editor {
	line-height: 2;
}

.param {
	border: 1px solid #000;
	border-radius: 5px;
	box-decoration-break: clone;
	padding: 2px 6px;
}
.param:hover {
	cursor: grab;
}
.param[data-id="#if"],
.param[data-id="/if"],
.param[data-id="isGreater"],
.param[data-id="isLess"] {
	background-color: #03a8f4;
	color: #fff;
	border-color: #006593;
}
.isEmpty:first-child::before {
	color: #a8adb2;
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
}
