.screenContainer {
    height: calc(100vh - 97px);
    flex: 1 1 0;
    display: flex;
    background-color: white;
    margin-left: 20px;
    margin-right: 20px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    overflow: hidden;
}
