.items {
   background: #fff;
   border-radius: 8px;
   box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
   color: rgba(0, 0, 0, 0.8);
   font-size: 14.4px;
   overflow: hidden;
   padding: 3.2px;
   position: relative;
}

.item {
   background: transparent;
   border: 1px solid transparent;
   border-radius: 6.4px;
   display: block;
   margin: 0;
   padding: 3.2px 6.4px;
   text-align: left;
   width: 100%;
}
.item.isSelected {
   border-color: #000;
}
