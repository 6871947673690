.wrapper {
	position: fixed;
	z-index: 9;
	width: 1100px;
	background: white;
	box-shadow: 0px 6px 20px 4px rgb(0 0 0 / 30%);
	border-radius: 5px;
	animation: opacity 0.4s;
}

.displayFlexSpaceB {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.content {
	width: 1100px;
	height: 500px;
	background: white;
}

.historyTable {
	display: flex;
	width: 100%;
	height: 150px;
	gap: 8px;
}

.row {
	display: flex;
	width: 100%;
	gap: 8px;
}

.streetRow {
	/* height: 100%; */
}

.row > div:first-child {
	width: 100%;
}

.row > div:last-child {
	width: 30%;
}

.fullSizeRow {
	display: flex;
	width: 100%;
	gap: 8px;
}

.selectedItem {
	height: 32px;
	width: 100% !important;
	padding: 5px 7px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.fieldsContainer {
	width: 40%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px;
}

.mapContainer {
	width: 80%;
	height: 100%;
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	position: relative;
}

.mapHeader {
	height: 43px;
	display: flex;
	align-items: center;
}

.headerContent {
	width: 95%;
}

.header {
	width: 893px;
	height: 31px;
	background-color: #21333f;
	border-radius: 5px 5px 0px 0px;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	cursor: default;
	padding: 0 5px;
	color: #ffffff;
}

.button {
	width: 16px;
	cursor: pointer;
}

.dropButton {
	width: 12px;
	height: 12px;
	cursor: grab;
}

.page {
	padding: 0 !important;
}

.closeButton {
	fill: #9ba3a8;
}

.footer {
	width: 100%;
	border-radius: 0 0 5px 5px;
	background-color: white;
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	padding: 8px;
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.headerCell {
	font-weight: bold !important;
	font-size: 11px;
	line-height: 13px;
	height: 26px;
	background: #ebebeb !important;
	color: #21333f !important;
}

.index {
	width: 80px;
}

.tableRow:hover {
	background: #f2f2f2;
}

.tableRow {
	height: 26px !important;
}

.table {
	max-height: 300px;
	margin: 0 0 !important;
}

.tableContainer {
	height: 180px;
	overflow-y: scroll;
}
