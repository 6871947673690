.containerDirectory {
  width: 100%;
  height: calc(100vh - 64px);
  background-color: #ebebeb;
}
.subHeader {
  width: 100%;
  height: 33px;
  display: flex;
  padding-left: 30px;
  padding-top: 9px;
}
.linkMain {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0125em;
  color: #647079;
  cursor: pointer;
}

.linkDirectory {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0125em;
  color: #21333f;
  cursor: pointer;
}

.subHeader :nth-child(n) {
  margin-right: 10px;
}

/* main style start */
.main {
  /* width: 100%; */
  /*height: 746px;*/
  display: flex;
}

/* main style end */
